@use 'assets/styles/utils/helper' as h;

.section {
  display: flex;
  position: relative;
  background-color: var(--accent-orange-light);

  @include h.breakpoint-m {
    background: linear-gradient(to right, var(--accent-orange-light) 65%, transparent 0%);
  }

  @include h.breakpoint-l {
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
  }
}

div.container {
  display: flex;
  flex-direction: column;

  @include h.breakpoint-m {
    flex-direction: row;
    min-height: h.rem(165px);
    margin: 0 auto;
    max-width: 1260px;
  }

  @include h.breakpoint-l {
    min-height: h.rem(215px);
  }

  @include h.breakpoint-x {
    min-height: h.rem(270px);
  }

  @include h.breakpoint-xx {
    min-height: h.rem(345px);
  }
}

.title {
  color: var(--grey-60);
  font-family: var(--primary-font);
  font-size: h.rem(28px);
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: h.rem(30px);
  margin-block-start: 0;

  @include h.breakpoint-m {
    font-size: h.rem(16px);
    max-width: h.rem(380px);
    margin-bottom: h.rem(15px);
  }

  @include h.breakpoint-l {
    font-size: h.rem(20px);
    max-width: h.rem(450px);
  }

  @include h.breakpoint-x {
    font-size: h.rem(24px);
    max-width: h.rem(500px);
    margin-bottom: h.rem(30px);
  }

  @include h.breakpoint-xx {
    font-size: h.rem(28px);
  }
}

.left {
  padding: h.rem(30px 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.logo {
  height: h.rem(76px);
  object-fit: contain;
  display: flex;
  align-self: center;
  margin-bottom: h.rem(30px);

  @include h.breakpoint-m {
    height: h.rem(45px);
    margin-top: 0;
    align-self: flex-start;
    justify-content: flex-start;
  }

  @include h.breakpoint-l {
    height: h.rem(58px);
  }

  @include h.breakpoint-xx {
    height: h.rem(75px);
  }
}

.button {
  margin: 0;
  background-color: transparent;
  font-size: h.rem(20px);
  height: h.rem(47px);
  align-self: center;

  @include h.breakpoint-m {
    align-self: flex-start;
  }
}

.disclaimer {
  font-family: var(--secondary-font);
  font-size: h.rem(16px);
  color: var(--grey-60);
  margin-top: h.rem(30px);
  margin-block-end: 0;

  @include h.breakpoint-m {
    margin-top: h.rem(25px);
    max-width: h.rem(380px);
  }

  @include h.breakpoint-l {
    margin-top: h.rem(30px);
    max-width: h.rem(450px);
  }

  @include h.breakpoint-x {
    max-width: h.rem(500px);
  }
}

.right {
  order: -1;

  @include h.breakpoint-m {
    clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 10% 100%);
    order: 0;
    transform: translateX(5%);
    width: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include h.breakpoint-m {
      position: absolute;
    }
  }
}
